<template>
  <section>
    <div class="pageBradcrumbs">
      <div class="containerLarge">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb small">
            <li class="breadcrumb-item">
              <b-button @click="toGlobal('/marine', 'marine')">Home</b-button>
            </li>
            <li class="breadcrumb-item">
              <b-button @click="toGlobal('/products', 'marine')">Products</b-button>
            </li>
            <li class="breadcrumb-item cursor-pointer" >
              <b-button @click="toCategoryParent()"> {{getCategoryName}}</b-button>
             
            </li>
          </ol>
        </nav>
      </div>
    </div>
    <div class="banner">
      <div
        class="bannerImage"
        :style="{
          'background-image':
            'url(' + getCover(element.cover) + ')',  
        }" 
      >
        <div class="containerLarge">
          <div class="pageTitle-banner text-center">
            <div class="pageTitle-content">
              <h1 class="mt-4">{{element.name}}</h1>
              <div class="divider"></div>
              <p class="lead">
                {{element.subtitle}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="caterory mt-5 pt-lg-3">
      <div class="container">
        <div class="row d-flex flex-row">
          <div class="col-md-6 align-self-center">
            <div class="lead" v-html="element.abstract">
            </div>
          </div>
          <div class="col-md-6 align-self-center text-center">
            <img :src="getImage(element.imgproducts)" alt="">                   
          </div>
        </div>
      </div>
      <div class="accordion mt-4 mt-lg-5" role="tablist">
        <!-- accordion -->
        <b-card v-if="element.sizes" no-body class="cardAccordion my-1">
          <b-card-header header-tag="header" class="card-header" role="tab">
            <div class="container px-0">
                <h2 class="h4 mb-0">
                    <a v-b-toggle.accordion-1 class="text-decoration-none d-flex flex-row justify-content-between">
                       <span>Available Sizes </span>
                       <b-icon-chevron-right></b-icon-chevron-right>
                    </a>
                </h2>
            </div>
          </b-card-header>
          <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <div class="container"> 
                <div class="row mt-2 listModel">              
                  <div v-html="element.sizes"></div>
                </div>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- accordion -->
        <b-card v-if="element.configurations" no-body class="cardAccordion my-1">
          <b-card-header header-tag="header" class="card-header" role="tab">
            <div class="container px-0">
                <h2 class="h4 mb-0">
                    <a v-b-toggle.accordion-2 class="text-decoration-none d-flex flex-row justify-content-between">
                       <span>Available Configurations </span>
                       <b-icon-chevron-right></b-icon-chevron-right>
                    </a>
                </h2>
            </div>
          </b-card-header>
          <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <div class="container"> 
                <div class="row mt-2 listModel-big">
                    <div v-html="element.configurations"></div>
                </div>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- accordion -->
        <b-card v-if="element.moreinfo_column1" no-body class="cardAccordion my-1">
          <b-card-header header-tag="header" class="card-header" role="tab">
            <div class="container px-0">
                <h2 class="h4 mb-0">
                    <a v-b-toggle.accordion-3 class="text-decoration-none d-flex flex-row justify-content-between">
                       <span>More Information</span>
                       <b-icon-chevron-right></b-icon-chevron-right>
                    </a>
                </h2>
            </div>
          </b-card-header>
          <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <div class="container"> 
                <div class="row mt-3">
                  <div class="col-md-6">
                    <div v-html="element.moreinfo_column1"></div>
                  </div>
                  <div class="col-md-6">
                      <div v-html="element.moreinfo_column2"></div>
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- accordion -->
        <b-card v-if="element.documents? element.documents.length > 0 : false"  no-body class="cardAccordion my-1">
          <b-card-header header-tag="header" class="card-header" role="tab">
            <div class="container px-0">
                <h2 class="h4 mb-0">
                    <a v-b-toggle.accordion-4 class="text-decoration-none d-flex flex-row justify-content-between">
                       <span>Documents</span>
                       <b-icon-chevron-right></b-icon-chevron-right>
                    </a>
                </h2>
            </div>
          </b-card-header>
          <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <div class="container"> 
                <ul class="listDocuments list-unstyled">
                  <li v-for="doc in orderedDocs(element.documents)" :key="doc.name">
                      <a v-if="!doc.archive" class="py-3" @click="show(doc)">
                          <div class="float-left pr-3">
                              <b-icon-file-earmark-text-fill font-scale="2"></b-icon-file-earmark-text-fill>
                          </div>
                          <div class="listDocuments-info">
                              {{doc.name}}
                          </div>
                      </a>
                  </li>
                </ul>
                <b-modal v-model="showModal" id="modal-1" title="Documents Downloads" hide-footer>
                  <form-download :name="name" :file="file" :link="link" :type="type" :parent="element.name" :categoryId="getCategoryId" :elementId="getModelId" @close="close"></form-download>
                </b-modal>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
    <div class="sticky-bottom mt-5 mb-5 text-center">
       <b-button class="btn btn-lg btn-turquoise py-2 px-5" @click="toSys(element.slug)">Size your system</b-button>
    </div>
  </section>
</template>

<script>
import FormDownload from '../components/FormDownload.vue';

export default {
  name: 'Model',

  components: {
    FormDownload
  },

  data: () => ({
    type: 'modello',
    name: '',
    file: '',
    link: false,
    showModal: false,
    element: {}
  }),

  computed: {
    getCurrentPath(){
      return this.$store.getters.getCurrentPath;
    },

    getCategoryName(){
      return this.$store.getters.getCategoryName; //this.$route.params.category_name;
    },
    getCategoryId(){
      return this.$store.getters.getCategoryId; //this.$route.params.category_id;
    },
    getModelId(){
      /* eslint-disable no-underscore-dangle */
      return this.element._id;
    }
  },

  mounted(){
    this.getModel();
  },

  methods: {

    orderedDocs(list){
      return list.slice().sort(this.compare);
    },
  
    compare(a, b) {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    },

    toGlobal(str, current){
      if (str !== this.getCurrentPath){
        this.$store.commit('setCurrentGlobal', current);
        this.$store.commit('setCurrentPath', str);
        this.$router.push(str);
      }      
    },

    toSys(slug){
      this.$store.commit('setCurrentGlobal', 'marine');
      const withSlug = '/size-your-system/' + slug;
      console.log('metto questo', withSlug, this.getCategoryName);
      this.$store.commit('setCurrentPath', withSlug);
      this.$store.commit('setCategoryId', this.getCategoryId);
      this.$store.commit('setCategoryName', this.getCategoryName);

      this.$router.push({
        name: 'Sys' + slug,
        params: {
          id: this.getCategoryId, //id categoria
          name: this.getCategoryName 
        }
      });
    },

    close(){
      this.showModal = false;
    },
    show(doc){
      
      this.name = doc.name;
      this.file = doc.file;
      this.link = doc.link ? doc.link : false;
      console.log('doc', doc, this.name, this.file);
      this.showModal = true;
    },

    toCategoryParent(){
      const str = '/category/' + this.getCategoryId;
      this.toGlobal(str, 'marine');
      //this.$router.push('/category/' + this.$route.params.category_id);
    },

    getModel(){
      this.$http.get('/api/sito/modello/get-by-id/?', { params: { id: this.$route.params.id } })
        .then((result) => {
          console.log('result mod', result);
          this.element = result.data[0];
        })
        .catch((err) => console.log('Problem on db: getModel', err));
    },

    getCover(img) {
      /* eslint-disable global-require */
      const tmp = require('../assets/img/banner/slide-01.jpg');
      return img ? process.env.VUE_APP_CLOUDINARY_BASE + img : tmp;
    },

    getImage(imgproducts) {
      const bucket = process.env.VUE_APP_CLOUDINARY_BUCKET;
      return imgproducts
        ? process.env.VUE_APP_CLOUDINARY_BASE + imgproducts            
        : 'https://res.cloudinary.com/plurimedia/image/upload/v1495121472/plurimedia/placeholder.png';
    },
  }
};
</script>

<style>
  .modal-title {
      font-weight: 700;
  }
  .listModel li {
    padding-bottom: 0.5em;;
  }
  .listModel-big li {
    padding-bottom: 1em;;
  }
</style>
